// src/store/index.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    currentComponent: null,
    actMenu:false,
    anket: [],
    actions:[],
    user: {
      id: null,
      name: '',
      birthdate: null,
      avatar: '',
      searchsex: '',
      sex: '',
      about: '',
      token: '',
      tid:null
    },
  },
  mutations: {
    setCurrentComponent(state, component) {
      state.currentComponent = component;
    },
    SET_ANKET_DATA(state, data) {
      state.anket = data;
    },
    ADD_ANKET_DATA(state, data) {
      state.anket.push(...data) ;
    },
    DEL_ANKET_DATA(state,data){
      state.anket.splice(0, data);
    },
    SET_USER_DATA(state, data) {
      state.user = {
        ...state.user,  // Сохраняем старые значения
        ...data
      } 
    },
    SET_ACTIONS_DATA(state, data) {
      state.actions.push(data);
    },
    DELL_ACTIONS_DATA(state) {
      state.actions = [];
    },
    SET_MENU_DATA(state, data) {
      state.actMenu = data;
    },
  },
  actions: {
    changeComponent({ commit }, component) {
      commit('setCurrentComponent', component);
    },
    updateAnketData({ commit }, data) {
      commit('SET_ANKET_DATA', data);
    },
    addAnketData({ commit }, data) {
      commit('ADD_ANKET_DATA', data);
    },
    dellAnketData({ commit }, data) {
      commit('DEL_ANKET_DATA', data);
    },
    dellActionsData({ commit }, data) {
      commit('DEL_ACTIONS_DATA', data);
    },
    updateUserData({ commit }, data) {
      commit('SET_USER_DATA', data);
    },
    updateActionsData({ commit }, data) {
      commit('SET_ACTIONS_DATA', data);
    },
    updateMenuData({ commit }, data) {
      commit('SET_MENU_DATA', data);
    },
  },
});

export default store;