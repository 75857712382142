<template>
<div class="container my-4">
    <div class="overflow-auto" style="max-height: 100%;">

        <div v-if="endAnkets" class="row g-0 h-100 text-light">
                <h1>У вас еще нет взаимных лайков</h1>
        </div>
        <div class="loading position-absolute top-50 start-50 translate-middle-x" v-if="loading">
            <div class="gradient-div d-flex justify-content-center">
                <p class="position-absolute top-0 start-50 translate-middle-x"></p>
                <div class="spinner-border text-light " role="status"></div>
                <p class="position-absolute text-center text-light pt-4 mt-4">Загрузка взаимностей</p>
            </div>
        </div>
        
        <div v-for="(card, index) in match"  :key="index" class="card mx-auto shadow bg-white text-dark h-100 m-4" style="max-width: 540px;" >
            <div class="row g-0 h-100">
                <div class="col-4">
                    <img :src="`./api/users/f1/${card.avatar}`" class="img-fluid rounded-start h-100">
                </div>
                <div class="col-8 h-100">
                    <div class="card-body">
                        <div>
                            <h5 class="card-title">{{ card.name }}</h5>
                            <p class="card-text">{{ card.about }}</p>
                        </div>
                        <!-- Кнопка снизу -->
                        <a class="btn btn-dark w-100 mt-4" @click="getPeachUser(card.tid,card.name)">
                            <i class="bi bi-chat-dots"></i> Написать
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>
      
<script>
import { mapActions, mapState } from 'vuex';
import { fetchAnkets } from '../utils/api';
export default {
name: 'matchAnkets',
data() {
    return {
        loading:true,
        endAnkets:false,
        match:[],
    };
},
computed: {
    ...mapState(['user', 'currentComponent']),
    },
    methods: {
        ...mapActions(['changeComponent','updateMenuData']),
        async getFetchMatch(){
            const token = this.user.token
            const anketsUser = await fetchAnkets('/match',{token})
            if(anketsUser.data){
                //отображение анкет
                this.match.push(...anketsUser.data)
                this.loading = false
                this.endAnkets = false
            }else{
                this.loading = false
                this.endAnkets = true
            }
        },
        async getPeachUser(ttid,ancetName){
            const data = {
                ...this.user,
                ttid,
                ancetName
            }
            await fetchAnkets('/getPeachUser',{data})
        }
    },
    mounted() {
      this.updateMenuData(true)
      this.getFetchMatch()
    },
}
</script>
      
<style scoped>
.card {
    max-height: 300px; /* Задаем максимальную высоту карточки */
}
img {
    object-fit: cover; /* Сохраняет пропорции изображения, заполняя контейнер */
    height: 100%;      /* Занимает 100% высоты родителя */
    width: 100%;       /* Занимает 100% ширины родителя */
}
</style>
      