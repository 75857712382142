<template>
  <main class="container messDiv">
    <div class="p-5 rounded mt-3">
      <div class="col-md-7 col-lg-8">
        <div class="profile-header">
          <img
            :src="user.avatar || require('@/assets/noAvatar.jpg')"
            alt="Avatar"
            @click="triggerFileInput"
            style="cursor: pointer; border: 5px solid; border-color: aliceblue;"
          />
        </div>
        <h4 class="mb-3 text-center">Заполните ваш профиль</h4>
        <form @submit.prevent="handleSubmit" class="needs-validation" enctype="multipart/form-data">
          <div class="row g-3">
            <div class="col-12">
              <label for="firstName" class="form-label">Ваше имя</label>
              <input 
                v-model="user.name" 
                type="text" 
                class="form-control" 
                placeholder="" 
                required 
                @input="validateName"
                title="Только буквы и пробелы"
              />
            </div>

            <div class="col-md-5">
              <label for="birthdate" class="form-label">Дата рождения</label>
              <input 
                v-model="user.birthdate" 
                type="date" 
                class="form-control" 
                required
                :max="maxDate"
                @change="validateBirthdate"
              />
            </div>

            <div class="col-md-5">
              <label class="form-label">Ваш пол</label>
              <select v-model="user.sex" class="form-select" id="sex" required>
                <option value="">Выбор ...</option>
                <option value="1">Мужчина</option>
                <option value="2">Девушка</option>
              </select>
            </div>

            <div class="col-12">
              <label for="about" class="form-label">Описание</label>
              <input 
                v-model="user.about" 
                type="text" 
                class="form-control" 
                placeholder="Люблю гулять по Москве" 
                required 
                @input="validateAbout"
                title="Только буквы и пробелы"
              />
            </div>

            <div class="col-md-5">
              <label class="form-label">Вы ищите</label>
              <select v-model="user.searchsex" class="form-select" id="search" required>
                <option value="">Выбор ...</option>
                <option value="2">Девушек</option>
                <option value="1">Мужчин</option>
              </select>
            </div>
          </div>
          <input
            type="file"
            ref="fileInput"
            style="display: none;"
            @change="handleFileChange"
            accept="image/png, image/jpeg, image/jpg"
          />
          <hr class="my-4" />

          <button class="w-100 btn btn-lg" :disabled="isDisabled" type="submit">Сохранить</button>
        </form>
      </div>
    </div>
    <br /><br /><br /><br />
  </main>
</template>

<script>
import { crAnketPhoto } from '../utils/api';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'createPerson',
  data() {
    return {
      event_avatar: '',
      isDisabled:false,
      tg: window.Telegram.WebApp,
      maxDate: new Date().toISOString().split("T")[0], // Устанавливаем максимальную дату как сегодняшнюю
    };
  },
  computed: {
    ...mapState(['user', 'currentComponent']),
  },
  methods: {
    ...mapActions(['updateUserData', 'changeComponent']),
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleSubmit() {
      this.updateUserData(this.user);
      if (this.user.name && this.user.avatar && this.user.searchsex && this.user.sex) {
        this.uploadImage();
      } else {
        alert('Данных не хватает для создания анкеты, вы загрузили фото?');
      }
    },
    validateName() {
      this.user.name = this.user.name.replace(/[^A-Za-zА-Яа-яЁё\s]/g, '');
    },
    validateAbout() {
      this.user.about = this.user.about.replace(/[^A-Za-zА-Яа-яЁё0-9\s.,!?;:()'"-]/g, '');
    },
    validateBirthdate() {
      // Дополнительная логика для проверки возраста
      const birthYear = new Date(this.user.birthdate).getFullYear();
      const currentYear = new Date().getFullYear();
      const age = currentYear - birthYear;
      if (age < 16 || age > 60) {
       // alert('Возраст должен быть от 16 до 60 лет.');
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file && ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        this.event_avatar = file; // Сохраняем файл напрямую для передачи на сервер
        this.previewImage(file); // Оставляем для предварительного просмотра
      } else {
        alert('Only JPG and PNG files are allowed!');
      }
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.user.avatar = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    uploadImage() {
      if (!this.user.avatar) {
        alert('Пожалуйста, выберите изображение для загрузки.');
        return;
      }
      const formData = new FormData();
      formData.append('file', this.event_avatar);
      delete this.user.avatar;
      formData.append('data', JSON.stringify(this.user));
      formData.append('token',JSON.stringify(this.user.token)); 
      this.isDisabled = true
      crAnketPhoto('/upload', formData)
        .then(data => {
          if (data) {
            this.updateUserData({ token: data.token });
            this.changeComponent('sucReg');
          }
        })
        .catch(error => {
          alert('Ошибка при загрузке');
          console.error('Error:', error)
        });
    },
  },
};
</script>

<style scoped>
.messDiv {
  width: 100vw;
  height: 100%;
  background: linear-gradient(45deg, #ff7e5f, #feb47b);
  color: white;
  margin-top: -10%;
}

.messDiv button {
  color: #feb47b;
  width: 300px;
  background-color: aliceblue;
}

.messDiv input, select {
  color: #feb47b;
  background-color: aliceblue;
  border-color: aliceblue;
  text-align: center;
}

.profile-header {
  text-align: center;
  margin: 10px 0;
}

.profile-header img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}
</style>