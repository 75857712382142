<template>
     <main class="container mb-9 ">
      <div class="p-4 rounded mt-2">
        <div class="profile-header" >
          <img
               :src="'/api/users/f1/'+user.avatar || 'noAvatar.jpg'"
                alt="Avatar"
                style="cursor: pointer;"
          >          
          <br>
          <br>
          <h2>{{ user.name }}</h2>
          <p>Возраст: {{ calculateAge(user.birthdate) }}</p>
          <div class="profile-info">
            <p>Описание: {{ user.about }}</p>
            <p>Редактирование анкет временно недоступно. Приложение находится в разработке</p>
          </div>
        </div>
      </div>
      <br>
    </main>
  </template>
  
  <script>
 import { mapState } from 'vuex';
  export default {
  name: 'editPerson',
  data() {
    return {

    };
  },
  methods: {
    calculateAge(birthdate) {
      const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
  },
  computed: {
    ...mapState(['user'])
  },
  created() {
   
  }
};
  </script>
  
  <style scoped>
  /* Стили для компонента Home */
  .profile-header {
      text-align: center;
      margin: 20px 0;
      color:#fff;
    }
    .profile-header img {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      object-fit: cover;
    }
    .profile-info {
      text-align: center;
      margin: 20px 0;
    }
    .bgGb{
      background-color: #ff7e5f;
    }
    .gradient-div {
      margin-top: -5%;
      padding-bottom: 20%;
      color:#fff;
      background: linear-gradient(45deg, #ff7e5f, #feb47b);
    }

  </style>
  